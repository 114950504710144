import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DataMigration1Image from '../images/data-migration/data-migration1.png';
import DataMigration2Image from '../images/data-migration/data-migration2.png';
import DataMigration3Image from '../images/data-migration/data-migration3.png';
import DataMigration4Image from '../images/data-migration/data-migration4.png';
import DataMigration5Image from '../images/data-migration/data-migration5.png';
import DataMigration6Image from '../images/data-migration/data-migration6.png';
import DataMigration7Image from '../images/data-migration/data-migration7.png';

const InsidersGuideToSuccessful = () => (
  <Layout>
    <SEO title="Insider's Guide To Successful Document Conversion And Data Migration" />
    <div className="page-content">
      <div className="container">
        <h1 className="text-orange text-center">Insider's Guide To Successful Document Conversion And Data Migration</h1>
        <div className="py-4" />
        <h2 className="mb-3">
            <span className="text-muted">Document Scanning Services</span><br/>
            <span className="h1">Buyer's Guide</span>
        </h2>
        <div className="row">
          <div className="col-md-6">
            <p>
              We understand how important your records are to you. They 
              preserve history, hold confidential information, represent years of 
              transactions – they're your job – and they cannot be replaced.
            </p>
            <p>
                We've been a trusted records management company for over 30 
                years, and we’re here to help.
            </p>
            <p>
                At some point, every organization – big or small, is going to be 
                faced with the decision:
            </p>
          </div>
          <div className="col-md-6">
            <p className="font-weight-bold">
                Is now the time to digitize our records?
            </p>
            <p>
                Sometimes it’s more cost-effective to maintain paper records, and 
                chances are, if you have a lot of paper records, you’ve got a really 
                great system for archival and retrieval. 
            </p>
            <p>
                Regardless of whether your paper situation is fully under control, 
                or it’s gotten way out of hand, scanning documents adds risks. 
            </p>
          </div>
        </div>
        <div className="py-4" />
        <div className="row">
          <div className="mx-auto col-lg-7">
            <div className="bg-black border-orange rounded p-5">
              <h3 className="mb-3">Risks Of Scanning</h3>
              <ul>
                <li>Where will my scanned documents be stored?</li>
                <li>How do I know if I got them all?</li>
                <li>How will I find records?</li>
                <li>How can I secure sensitive data? </li>
                <li>It's more work to search through my computer than to just go find the file!</li>
                <li>And what happens if I lose the data?</li>
              </ul>
            </div>
          </div>
        </div>
        <p className="h4 text-center mt-3">The list goes on and on. We understand.</p>
      </div>
      <div className="py-4" />
      <div className="py-5" style={{ backgroundColor: '#2d2d2d' }}>
        <div className="container">
          <div className="h2 text-center text-orange font-family-orbitron-sans-serif">
            Be A Part Of Something Bigger Than Just Document Scanning - Your Organization Will Thank You.
          </div>
        </div>
      </div>
      <div className="py-4" />
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <img className="w-100" src={DataMigration1Image} alt="A New Approach – Data Migration"/>
          </div>
          <div className="col-md-7">
            <h3 className="mb-4">A New Approach – Data Migration</h3>
            <p>
              Data migration is a process to extract important information 
              from your records and insert it into your line of business 
              software, content repository, or file share. 
            </p>
            <p className="font-weight-bold text-orange">
              The time is coming when electronic access to information 
              locked away on paper records will either be required by 
              law or the advantage you need to beat out competition. 
            </p>
            <p>
              If you’ve waited to do something with your paper records, now 
              is a better time to act than ever. 
            </p>
            <ul>
              <li>Immediate and organized access to all information</li>
              <li>Full control of confidential information</li>
              <li>Safeguard intellectual property</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="py-4" />
      <div className="container">
        <div className="bg-black border-orange rounded p-5">
          <h3 className="mb-3">HOW MUCH DOES IT COST?</h3>
          <p>
            As with any project, there are a lot of variables involved. Here are just some of the factors that 
            affect price: 
          </p>
          <ul>
            <li>
              <span className="font-weight-bold text-orange">Timeline: </span>
              Do you need data back in days or over the period of months or years?
            </li>
            <li>
              <span className="font-weight-bold text-orange">Volume: </span>
              How many total pages are involved?
            </li>
            <li>
              <span className="font-weight-bold text-orange">Location and Condition of Records: </span>
              Will records be converted at your 
              facility or shipped out for processing? Are any records damaged or 
              extremely fragile (like old onion skin paper or historical records)?
            </li>
            <li>
              <span className="font-weight-bold text-orange">Prep required: </span>
              Are there staples, paper clips, folders, envelopes, etc?
            </li>
            <li>
              <span className="font-weight-bold text-orange">Specialty documents: </span>
              Are there photos, sticky notes, seals, certified
              receipts, spiral or bound books, microform, newspaper clippings, or
              continuous feed documents (that can be hundreds of feet long), or
              oversize prints?
            </li>
            <li>
              <span className="font-weight-bold text-orange">Re-assembly: </span>
              Will the records be re-assembled in their 
              original condition?
            </li>
            <li>
              <span className="font-weight-bold text-orange">Indexing: </span>
              Do you require 100% hard-copy vs digital review 
              or only a percentage? 
            </li>
            <li>
              <span className="font-weight-bold text-orange">Data delivery: </span>
              How will extracted data and document images be 
              delivered? In what format, and into what system(s)?
            </li>
            <li>
              <span className="font-weight-bold text-orange">Post project: </span>
              Will documents be returned, held, or destroyed?
            </li>
          </ul>
          <p className="font-weight-bold text-orange">
            A data migration expert will help create accurate expectations.
          </p>
        </div>
      </div>
      <div className="py-4" />
      <div className="container">
        <div className="bg-black border-orange rounded p-5">
          <h3 className="mb-3">HOW TO CHOOSE A VENDOR?</h3>
          <p>
            Choosing the right vendor for your project is important. Here are 
            6 criteria to make an evaluation:
          </p>
          <ol className="ol-marker-bold-orange">
            <li>
              <span className="font-weight-bold text-orange">Years in business: </span>
              The longer the better. Chances are, you have 
              some very old records, and unless the vendor has been around for 
              a decade or more, they probably haven’t had enough experiences 
              to ensure high-quality results.
            </li>
            <li>
              <span className="font-weight-bold text-orange">Facility: </span>
              At a minimum there should be security systems, 
              access control, video monitoring, humidity control, lockable 
              storage, and an inventory control system. Tour the facility and 
              check for paper dust – it’s an easy predictor of quality because 
              paper dust interferes with scanning throughput and quality.
            </li>
            <li>
              <span className="font-weight-bold text-orange">Compliance: </span>
              Request proof of Sarbanes Oxley, HIPAA, SOC II, 
              and PCI compliance.
            </li>
            <li>
              <span className="font-weight-bold text-orange">Scanner technology: </span>
              Large projects require specialty scanners 
              for your unique document types. There should be many different 
              types of equipment, including micrographics and large format 
              scanners. 
            </li>
            <li>
              <span className="font-weight-bold text-orange">Software technology: </span>
              Find out what software will be used to 
              process your documents. This software will be responsible for 
              improving document images, and the accuracy of data migration. 
              The software should include features for image processing, 
              optical character recognition (OCR), and diverse integration with 
              other software applications. 
            </li>
            <li>
              <span className="font-weight-bold text-orange">Staff: </span>
              Because your project will require very high attention to 
              detail, don’t overlook the qualifications and morale of the staff 
              who will be performing the work. They should be excited to work 
              on your project and focused on results. 
            </li>
          </ol>
        </div>
      </div>
      <div className="py-4" />
      <div className="container">
        <h3 className="mb-3">WHY CHOOSE GRAY HAWK and BIS?</h3>
        <p>
          BIS has been digitizing records for over 30 years. We process over 
          50 million records per year and have the experience, technology, 
          equipment, and staff needed to guarantee results. You don’t have 
          to take our word for it. 
        </p>
        <div className="row">
          <div className="col-md-4">
            <div className="p-2 border rounded mb-3">
              <p className="text-center font-italic">
                “We have records dating back to 1877 that could not be 
                replaced. These documents must be protected to maintain 
                the history as well as many legal requirements. Preserving 
                these records through BIS has provided high quality digital 
                scans and they have restored all my sewn books to pristine 
                condition. Grooper has preserved the integrity of our 
                historic records and enabled me to meet my goal of 
                providing records to the public in less than five 
                minutes per request.”
              </p>
              <p className="text-center font-weight-bold text-orange">
                Butch Scipper, Chancery Clerk, Quitman County, Mississippi
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="p-2 border rounded mb-3">
              <p className="text-center font-italic">
                “The quality and efficiency of work and service delivered 
                was more than we could’ve asked for. It’s always been an 
                easy relationship.”
              </p>
              <p className="text-center font-weight-bold text-orange">
                Gena Cowan, HIM Manager, Mercy Hospital
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="p-2 border rounded mb-3">
              <p className="text-center font-italic">
                “Space is a premium here, so being able to scan the paper 
                that we no longer need and have that available electronically 
                at a moment’s notice is tremendous value to us. Any time 
                that we can offer up the information quickly and efficiently 
                and accurately, we definitely show return on investment.”
              </p>
              <p className="text-center font-weight-bold text-orange">
                Greg Gann, IT Project Manager, Sedgwick County
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-4" />
      <div className="container">
        <h3 className="mb-3">A WORLD CLASS FACILITY</h3>
        <p className="font-weight-bold">
          Our 400,000 cubic foot data migration center exceeds U.S. and 
          international standards for securing your records. Rigorous 
          audits, training, and controls are in place for the following:
        </p>
        <div className="row">
          <div className="col-md-5">
            <img className="w-100 img-shadow-orange" src={DataMigration2Image} alt="" />
          </div>
          <div className="col-md-7">
            <ul>
              <li>SOC II Type II</li>
              <li>HIPAA Compliance</li>
              <li>Sarbanes Oxley (SOX)Compliance</li>
              <li>PCI Compliance</li>
              <li>PRISM Privacy+</li>
            </ul>
            <p>
              Our facility is protected with 24/7 
              access control and 360-degree 
              video surveillance. Humidity is 
              monitored and electronically 
              controlled to be consistent from 
              floor to ceiling in all record 
              storage areas
            </p>
            <p>
              Locked storage cage options, 
              employee background checks, 
              and federal security clearance 
              ensure the protection of your data.
            </p>
            <p>
              We provide full chain of custody. Upon arrival, your records are 
              checked into our inventory management system and tracked 
              throughout the entire process. 
            </p>
            <p>
              We provide secured records transportation, storage, and 
              certified destruction.
            </p>
          </div>
        </div>
      </div>
      <div className="py-4" />
      <div style={{ backgroundColor: '#f9730b' }}>
        <div className="p-4" />
        <div className="container">
          <h3 className="mb-3 text-white">A WORLD CLASS TEAM</h3>
          <div className="row">
            <div className="col-md-12 text-white">
              <p>
                With an average tenure of 4.5 years, 
                and a staff of over 20 employees, 
                we provide the most critical aspect 
                for success. Our people.
              </p>
              <p>
                Scanning operations are centered 
                around high efficiency workflows. 
                Teams of workers share in a single, 
                efficient process of transforming 
                physical records into the data 
                you require. 
              </p>
              <p className="mb-4">
                Our scanning team celebrates
                successes, decorates for the 
                holidays, and they enjoy their work. 
              </p>
            </div>
            <div className="col-md-8">
              <img className="w-100 img-shadow-white" src={DataMigration4Image} alt="" />
            </div>
            <div className="col-md-4">
              <img className="w-100 img-shadow-white" src={DataMigration3Image} alt="" />
            </div>
          </div>
        </div>
        <div className="p-4" />
      </div>
      <div className="py-4" />
      <div className="container">
        <h3 className="mb-3">OUR SCANNERS</h3>
        <div className="row">
          <div className="col-md-6">
            <img className="w-100 img-shadow-orange" src={DataMigration5Image} alt="" />
          </div>
          <div className="col-md-6">
            <p className="font-weight-bold">
              With a fleet of over 25 modern production-grade scanners, we’re 
              equipped to handle any project. Our equipment selection is based 
              on:
            </p>
            <ol className="mt-n3 mb-n2 ol-marker-bold-orange">
              <li>Highest image quality</li>
              <li>Speed, feed, and ergonomics</li>
              <li>Durability</li>
              <li>Ease of preventative maintenance (we clean all machines daily)</li>
            </ol>
            <p>
              Scanner brands include InoTec, imbl, Mekel, Nextscan, Zeutschel, 
              Canon, and Colortrac. 
            </p>
          </div>
        </div>
        <div className="py-3" />
        <h4 className="mb-3">WE PROCESS ANY TYPE OF PHYSICAL RECORD</h4>
        <div className="row">
          <div className="col-md-4">
            <ul>
              <li>Microfilm / microfiche / aperture cards, etc.</li>
              <li>CD / DVD</li>
              <li>Bound books</li>
              <li>Large format / blueprint</li>
              <li>Continuous logs</li>
              <li>Paper</li>
            </ul>
          </div>
          <div className="col-md-8">
            <img className="w-100 img-shadow-orange" src={DataMigration6Image} alt="" />
          </div>
        </div>
      </div>
      <div className="py-4" />
      <div className="container">
        <div className="row">
          <div className="mx-auto col-lg-9">
            <div className="bg-black border-orange rounded p-5">
              <h3 className="mb-3">OUR SOFTWARE</h3>
              <p>
                The quality of data migration and digitization of records is 
                dependent on three factors:
              </p>
              <ol className="mt-n3 mb-n2 ol-marker-bold-orange">
                <li>Image processing</li>
                <li>Optical Character Recognition (OCR)</li>
                <li>Intelligent data extraction</li>
              </ol>
              <p>
                We use Grooper, a patented artificial intelligence software 
                platform designed to read and process all forms of physical 
                and electronic documents. 
              </p>
              <ul>
              <li>
                <span className="font-weight-bold text-orange">Image processing: </span>
                  Grooper will enhance 
                  document images to the point that they 
                  look better than the original. Our scanning 
                  process produces crisp, clean, and perfect 
                  reproductions.
              </li>
              <li>
                <span className="font-weight-bold text-orange">OCR: </span>
                  Extracting accurate information from 
                  documents requires near-perfect OCR. 
                  Because Grooper reads documents like a 
                  human, we accurately capture text that is split 
                  up by columns, spread throughout tables, 
                  inside pictures or stamps, or improperly 
                  aligned on the page.
              </li>
              <li>
                <span className="font-weight-bold text-orange">Intelligent data extraction: </span>
                  Grooper uses artificial intelligence to determine what 
                  kind of document it is processing. Then, it 
                  extracts the information your project requires, 
                  like invoice, matter or case numbers, dates, 
                  names, etc. This information is used to 
                  classify and name your digital files and is also 
                  integrated into your line of business software, 
                  document repository, or file share. 
              </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="py-4" />
      <div className="container">
        <h3 className="mb-3">ADVANCED SERVICES</h3>
        <p>
          Quickly and efficiently outsource complex or time-consuming 
          business processes and workflows. 
        </p>
        <p>
          <span className="font-weight-bold">Oil and Gas Lease Data Analysis - </span>
          Extract complex data elements and identify value and obligations in new acquisitions.
        </p>
        <p>
          <span className="font-weight-bold">Non-Operator Oil and Gas Joint Ventures - </span>
            Remove manual 
            processes from Daily Drilling Reports and Gas Purchase / Percent 
            of Proceeds statements.
        </p>
        <p>
          <span className="font-weight-bold">Truth-In-Lending Compliance - </span>
          Identify irregularities and improve customer / member communication. 
        </p>
        <p>
          <span className="font-weight-bold">Explanation of Benefits - </span>
          Accelerate approvals, reconciliations, and payments. 
        </p>
        <p>
          <span className="font-weight-bold">Transcript Processing - </span>
          Gather and verify course information 
          for grade point calculations by course, semester, and complete 
          transcript.
        </p>
        <p>
          <span className="font-weight-bold">Government - </span>
          Scan paper or ingest digitally filed records to improve search and provide better services to citizens.
        </p>
      </div>
      <div className="py-4" />
      <div
        className="w-100"
        style={{ 
          backgroundImage: `url(${DataMigration7Image})`,
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '380px',
          opacity: '0.8'
        }}
        />
      <div className="py-5" />
      <div className="container">
        <h2 className="mb-3">
            <span className="text-muted">HOW TO</span><br/>
            <span className="h1">GET STARTED</span>
        </h2>
        <ol className="ol-marker-bold-orange">
          <li>SCHEDULE AN ONSITE OR VIRTUAL TOUR OF OUR FACILITY.</li>
          <li>PROVIDE A REPRESENTATIVE SAMPLE OF YOUR DOCUMENTS.</li>
          <li>COLLABORATE ON DESIRED BUSINESS OUTCOME.</li>
          <li>EXECUTE THE PROJECT.</li>
        </ol>
        <p className="text-center">
          Now is the time to protect your records with data migration. 
          Start small or go all-in, start now.
        </p>
        <p className="text-center font-weight-bold text-orange">
          Schedule your onsite or virtual tour today.
        </p>
      </div>
    </div>
  </Layout>
)

export default InsidersGuideToSuccessful
